import CoreRoutes, { SECURITY_PATH } from 'routing/Routes';

export const SECURITY_VIEW_ID_PREFIX = 'graylog-security-views__';

const Routes = {
  WELCOME: SECURITY_PATH,
  ACTIVITY: {
    OVERVIEW: `${CoreRoutes.SECURITY.ACTIVITY}`,
    USER_ACTIVITY: `${CoreRoutes.SECURITY.ACTIVITY}/user-activity`,
    HOST_ACTIVITY: `${CoreRoutes.SECURITY.ACTIVITY}/host-activity`,
    NETWORK_ACTIVITY: `${CoreRoutes.SECURITY.ACTIVITY}/network-activity`,
  },
  OVERVIEW: CoreRoutes.SECURITY.OVERVIEW,
  USER_ACTIVITY: CoreRoutes.SECURITY.USER_ACTIVITY,
  HOST_ACTIVITY: CoreRoutes.SECURITY.HOST_ACTIVITY,
  NETWORK_ACTIVITY: CoreRoutes.SECURITY.NETWORK_ACTIVITY,
  ANOMALIES: {
    LIST: CoreRoutes.SECURITY.ANOMALIES,
    DETECTORS: {
      LIST: `${CoreRoutes.SECURITY.ANOMALIES}/detectors`,
      CREATE: `${CoreRoutes.SECURITY.ANOMALIES}/detectors/create`,
      edit: (detectorId: string) => `${CoreRoutes.SECURITY.ANOMALIES}/detector/edit/${detectorId}`,
    },
  },
  ASSETS: {
    CONFIGURATION: `${SECURITY_PATH}/assets/configuration`,
    LIST: `${SECURITY_PATH}/assets`,
    USERS: `${SECURITY_PATH}/assets/users`,
    SOURCES: {
      LIST: `${SECURITY_PATH}/assets/sources`,
      show: (sourceType: string, sourceId: string) => `${SECURITY_PATH}/assets/sources/${sourceType}/${sourceId}`,
      forms: (sourceType: string) => `${SECURITY_PATH}/assets/sources/${sourceType}/forms`,
      form: (sourceType: string, sourceId: string) => `${SECURITY_PATH}/assets/sources/${sourceType}/forms/${sourceId}`,
      formMappings: (sourceType: string, sourceId: string) => `${SECURITY_PATH}/assets/sources/${sourceType}/forms/${sourceId}/mappings`,
      formMapping: (sourceType: string, sourceId: string, mapId: string) => `${SECURITY_PATH}/assets/sources/${sourceType}/forms/${sourceId}/mappings/${mapId}`,
    },
  },
  INVESTIGATIONS: {
    LIST: `${SECURITY_PATH}/investigations`,
    details: (investigationId: string) => `${SECURITY_PATH}/investigations/details/${investigationId}`,
    ARCHIVED: `${SECURITY_PATH}/investigations/archived`,
    CONFIGURATION: `${SECURITY_PATH}/investigations/configuration`,
    show: (investigationId: string) => `${SECURITY_PATH}/investigations/${investigationId}`,
  },
  SIGMA: {
    LIST: `${SECURITY_PATH}/sigma`,
    CONFIGURATION: `${SECURITY_PATH}/sigma/configuration`,
  },
  SECURITY_EVENTS: {
    ALERTS: `${SECURITY_PATH}/security-events/alerts`,
    DEFINITIONS: `${SECURITY_PATH}/security-events/definitions`,
    NOTIFICATIONS: `${SECURITY_PATH}/security-events/notifications`,
  },
};

export default Routes;
